
#companyindexpage {
	
	#company-yt-videos {
	
		picture {
			position: relative;
			
			.icon {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				font-size: 40px;
				background: #f00;
				color: #fff;
				border-radius: 50%;
			}
			
			&:hover {
				
				.icon {
					background: #fff;
					color: #f00;
				}
			
			}
		
		}
			
		.description {
			display: -webkit-box!important;
			overflow: hidden;
			margin-top: 8px;
			word-wrap: break-word;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			line-height: 1.4;
			font-size: 13px;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			height: 36.4px;
		}
		
	}
	
	.lots-scroll-list {
		
		.box-eq {
			width:220px;
			min-width: 220px;
			max-width: 220px;
		}
		
		.lots-list {
			
			.lots-list-box {
				
				border: 1px $white solid;
				border-bottom: 0;
				
				&:hover {
					border: 1px $primary-light solid;
				}
				
				a {
					color: auto;
					display: block;
				}
				
				.top-date {
					@include font-main;
					@include fs-12;
					@include fw-400;
					color: $gray-dark;
					margin-bottom: 5px;
				}
				
				.imgbox {
					margin: 10px 0;
					
					img {
						max-height: 135px;
						margin: 0px auto;
					}
				}
				
				.price {
					@include font-head;
					@include fs-20;
					@include fw-400;
					line-height: 0.54;
					color: $primary-dark;
					padding: 10px 0;
					text-align: center;
					
					sub {
						bottom: 0px;
						color: $gray-dark;
						@include fs-12;
					}
				}
				
				.name {
					@include font-main;
					@include fs-13;
					@include fw-400;
					line-height: 1.5;
					color: $primary-dark;
					padding-top: 8px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					text-align: center;
				}
				
			}
			
		}
		
	}
	
	
}


#companyabout {

	.about-us {
		
		.input-group-prepend {
			
			.input-group-text {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				font-size: 14px;
				background: #f1f5f6;
				border-color: #e9e9e9;
			}
		}
		
		.custom-file {
			height: 54px;
			
			.custom-file-label {
				height: 54px;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				line-height: 40px;
				font-size: 14px;
				border-color: #e9e9e9;
				
				&::after {
					height: auto;
					font-size: 14px;
					line-height: 40px;
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
					background: #f1f5f6;
					border-color: #e9e9e9;
					
				}
			}
		
		}
		
	}
	
}


#companypage {

	.text {
		font-size: 13px;
	}

}


#companyblog {
	
	.short {
		font-size: 14px;
	}

	.text {
		font-size: 14px;
		
		.table-bordered {
			border: 0px;
			
			td {
				border: 0px;
			}
		}
		
		h3 {
			margin-bottom: 15px;
		}
	}
	
}

#ibappage {
	
	.lotslist {
		
		.lotbox {
			margin-bottom: 10px;
			padding-left: 5px;
			padding-right: 5px;
			
			.lotinfo {
				height: 310px;
				border: 1px solid #eaeaea;
				border-radius: 10px;
				padding: 10px;
				
				&:hover {
					border: 1px solid #e0e0e0;
					-webkit-box-shadow: 0 0 5px 0 hsla(0,0%,78.4%,.3);
					-moz-box-shadow: 0 0 5px 0 hsla(0,0%,78.4%,.3);
					box-shadow: 0 0 5px 0 hsla(0,0%,78.4%,.3);
				}
				
				.imgbox {
					position: relative;
					text-align: center;
					height: 150px;
					
					.images {
						height: 100%;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						align-content: center;
						
						img {
							margin: 0 auto;
							display: inline-block;
							max-height: 132px;
							height: auto;
						}

					}
				}
				
				h3 {
					@include font-head;
					font-size: 13px;
					font-weight: 400;
					color: #353535;
					line-height: 1.2;
					height: 32px;
					margin: 2px 0;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				
				.details {
					margin-top: 10px;
					padding-top: 6px;
					border-top: 1px solid #eaeaea;
					color: #8c8c8c;
				}
			}
		}
		
	}
	
	.notfound {
		
		.bigicon {
			font-size: 30px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			text-align: center;
			line-height: 50px;
			background: $gray-light;
			color: #fff;
			margin: 20px;
			display: inline-block;
		}
		
	}
	
}
